import {
  Box,
  Button,
  Center,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { ReactComponent as XMark } from '../assets/xmark.svg';
import { ReactComponent as Check } from '../assets/circle-check.svg';
import { emittery } from '../../constants';
import { useEffect, useState } from 'react';

export function SuccessfulReportModal() {
  const [open, setOpen] = useState(false);
  const [item, setItem] = useState(null);

  const showReport = async () => {
    await emittery.emit('view:report', item);
  };

  useEffect(() => {
    const unlisten = emittery.on('new:report', (item) => {
      setItem(item);
      setOpen(true);
    });

    return () => {
      unlisten();
    };
  }, []);

  return (
    <Modal isOpen={open} isCentered size="3xl">
      <ModalOverlay>
        <ModalBody>
          <ModalContent>
            <Box display="flex">
              <ModalHeader ml="auto">
                <XMark height="16" width="16" />
              </ModalHeader>
            </Box>
            <Center>
              <Check
                onClick={(e) => {
                  e.preventDefault();
                  setOpen(false);
                  setItem(null);
                }}
              />
            </Center>
            <Center mt={4}>
              <Text fontWeight="bold" color="#005086" fontFamily="Ubuntu-Bold">
                Your report has been successfully submitted.
              </Text>
            </Center>
            <Center mt={4} mb={12}>
              <Button
                colorScheme="red"
                borderRadius={20}
                fontWeight="normal"
                fontFamily="Ubuntu-Medium"
                fontSize={14}
                backgroundColor="#FE0000"
                onClick={(e) => {
                  e.preventDefault();
                  setOpen(false);
                  showReport();
                }}
              >
                View Report
              </Button>
            </Center>
          </ModalContent>
        </ModalBody>
      </ModalOverlay>
    </Modal>
  );
}
