import api from './api';

const listAnnouncement = (params, callback) => {
  api
    .get(`/api/citizen/announcement?q=${params.q}`, {
      headers: {},
    })
    .then((response) => {
      callback(response);
      return response;
    })
    .catch((err) => {
      if (err) {
        if (err.response.data.errors) {
          callback(err.response);
          return err.response;
        }
      }
    });
};

const listReports = (params, callback) => {
  api
    .get(`/api/guest/report?q=${params.q}`, {
      headers: {},
    })
    .then((response) => {
      callback(response);
      return response;
    })
    .catch((err) => {
      if (err) {
        if (err.response.data.errors) {
          callback(err.response);
          return err.response;
        }
      }
    });
};
export { listAnnouncement, listReports };
