import axios from 'axios';

const createIncidentReport = (params, callback = null) => {
  axios
    .post(`${process.env.REACT_APP_BASE_URL}` + `/api/citizen/report`, params, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.data.errors) {
          callback(err.response);
        }
      }
    });
};

const listAnnouncement = (params, callback = null) => {
  axios
    .post(`${process.env.REACT_APP_BASE_URL}` + `/api/citizen/report`, params, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.data.errors) {
          callback(err.response);
        }
      }
    });
};

export { createIncidentReport, listAnnouncement };
