import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  Image,
  Heading,
  Box,
} from '@chakra-ui/react';
import moment from 'moment';
const AnnouncementModal = ({ isModalVisible, handleAnnouncemnt, data }) => {
  const date = moment(data.created_at).format('L');
  return (
    <Modal
      motionPreset="slideInBottom"
      isCentered
      isOpen={isModalVisible}
      onClose={handleAnnouncemnt}
      size={'4xl'}
    >
      <ModalOverlay />
      <ModalContent centerContent>
        <ModalHeader
          fontSize={24}
          backgroundColor={'#005086'}
          color={'white'}
          fontFamily={'Ubuntu-Medium'}
        >
          Announcement
        </ModalHeader>
        <ModalCloseButton color={'white'} />
        <ModalBody display={'flex'} flexDir={'column'} p={'0'}>
          <Box>
            <Image src={data.filename} width={'100%'} />
          </Box>

          <Heading as="h4" size="lg" color={'#000000'} mt="8" ml="8">
            {data.name}
          </Heading>
          <Heading
            as="h6"
            size="xs"
            fontSize={12}
            color={'#484848'}
            ml="8"
            mt="2"
            opacity="0.52"
          >
            Posted on {data.created_at}
          </Heading>
          <Text color={'#000000'} fontSize={15} ml="3" p="5">
            {data.description}
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="blue"
            bg="#005086"
            w="141px"
            h="61px"
            borderRadius={20}
            onClick={handleAnnouncemnt}
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AnnouncementModal;
