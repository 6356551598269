/**
 * @returns {Promise<{ latitude: number, longitude: number }>}
 */
export function getLocation() {
  return new Promise((resolve, reject) => {
    const Geolocation = navigator.geolocation;

    Geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;

        resolve({ latitude, longitude });
      },
      (error) => reject(error),
      { enableHighAccuracy: true }
    );
  });
}
