import SectionE from './main_components/sectionE';
import React from 'react';
import Footer from './components/footer';
import Header from './components/header';
import SectionA from './main_components/sectionA';
import SectionB from './main_components/sectionB';
import SectionC from './main_components/sectionC';
import SectionD from './main_components/sectionD';

const Layout = () => {
  return (
    <>
      <Header />
      <SectionA />
      <SectionB />
      <SectionC />
      <SectionD />
      <SectionE />
      <Footer />
    </>
  );
};

export default Layout;
