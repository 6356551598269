import './App.css';
import Layout from './landingPage/layout';
import 'toastr/build/toastr.css';
import toastr from 'toastr';
import '@avidian/extras';

window.toastr = toastr;

function App() {
	return <Layout />;
}

export default App;

