import React from 'react';
import {
  Box,
  Image,
  Text,
  List,
  ListItem,
  ListIcon,
  Center,
} from '@chakra-ui/react';
import Background from '../assets/footerBanner.jpg';
import Logo from '../assets/logo.png';
import { ReactComponent as Phone } from '../assets/phone.svg';
import { ReactComponent as Envelope } from '../assets/envelope-open-text.svg';
import { ReactComponent as MobileScreen } from '../assets/mobile-screen-button.svg';

const Footer = () => {
  return (
    <Box
      display={'flex'}
      width={'100%'}
      backgroundImage={Background}
      backgroundSize={'cover'}
      backgroundRepeat={'no-repeat'}
      backgroundPosition={'center'}
      justifyContent={'center'}
      flexDir={'column'}
    >
      <Box
        display={'flex'}
        flexDir={['column', 'column', 'column', 'row', 'row', 'row']}
      >
        <Box
          backgroundColor={'blackAlpha.500'}
          display={'flex'}
          justifyContent={'space-between'}
          width={'100%'}
        >
          <Box
            display={'flex'}
            flexDir={['column', 'column', 'column', 'row', 'row', 'row']}
            alignItems={'center'}
            pl={[0, 0, 0, 0, 40]}
          >
            <Image
              src={Logo}
              w={['50%', '50%', '50%', '25%', '25%', '25%']}
              my={[10, 10, 10, 20, 20, 20]}
            />
            <Box
              display={'flex'}
              flexDirection={'column'}
              alignItems={[
                'center',
                'center',
                'center',
                'start',
                'start',
                'start',
              ]}
              color={'white'}
              fontFamily={'Ubuntu-Bold'}
              fontSize={25}
              mx={3}
            >
              <Text fontSize={26}>Sto. Domingo Disaster</Text>
              <Text fontSize={26}>Inventory System</Text>
              <Text fontSize={26}>Development</Text>
            </Box>
          </Box>
        </Box>
        <Box
          backgroundColor={'blackAlpha.500'}
          display={'flex'}
          justifyContent={['center', 'center', 'center', 'end', 'end', 'end']}
          width={'100%'}
          pr={[0, 0, 0, 44, 44, 44]}
        >
          <Center>
            <List
              my={[10, 10, 10, 0, 0, 0]}
              gap={[0, 0, 10, 0, 0, 0]}
              spacing={[0, 0, 0, 10, 10, 10]}
              display={'flex'}
              flexDir={[
                'column',
                'column',
                'row',
                'column',
                'column',
                'column',
              ]}
              justifyContent={[
                'center',
                'center',
                'center',
                'end',
                'end',
                'end',
              ]}
            >
              <ListItem
                display={'flex'}
                flexDir={['row', 'row', 'column', 'row', 'row', 'row']}
                alignItems={[
                  'start',
                  'start',
                  'center',
                  'start',
                  'start',
                  'start',
                ]}
                color={'white'}
              >
                <ListIcon as={Phone} color="white" size={50} fontSize={40} />
                <Text fontSize={20}>163-189-000</Text>
              </ListItem>
              <ListItem
                display={'flex'}
                flexDir={['row', 'row', 'column', 'row', 'row', 'row']}
                alignItems={[
                  'start',
                  'start',
                  'center',
                  'start',
                  'start',
                  'start',
                ]}
                color={'white'}
              >
                <ListIcon as={Envelope} color="white" size={50} fontSize={40} />
                <Text fontSize={20}>stodomingo@gmail.com</Text>
              </ListItem>
              <ListItem
                display={'flex'}
                flexDir={['row', 'row', 'column', 'row', 'row', 'row']}
                alignItems={[
                  'start',
                  'start',
                  'center',
                  'start',
                  'start',
                  'start',
                ]}
                color={'white'}
              >
                <ListIcon
                  as={MobileScreen}
                  color="white"
                  size={50}
                  fontSize={40}
                />
                <Text fontSize={20}>09123456789</Text>
              </ListItem>
            </List>
          </Center>
        </Box>
      </Box>
      <Box
        w={'100%'}
        backgroundColor={'blackAlpha.500'}
        justifyContent={'center'}
        textAlign={'center'}
        display={'flex'}
        color={'white'}
        fontFamily={'Ubuntu-Medium'}
        fontWeight={'600'}
      >
        2022 @ Sto. Domingo - Disaster Inventory System Development. All Rights
        Reserved
      </Box>
    </Box>
  );
};

export default Footer;
