import React, { useEffect, useState } from 'react';
import { Box, Image, Text, Button, useToast } from '@chakra-ui/react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import ReportsModal from '../modals/reportsModal';
import { listReports } from '../../api/request';
import { emittery } from '../../constants';
import { SuccessfulReportModal } from '../modals/successfulReportModal';

const SectionC = () => {
  const toast = useToast();

  const [reports, setReports] = useState([]);
  const [reportsIncluded, setReportsIncluded] = useState([]);
  const [showReport, setShowReport] = useState(false);
  const [selectedValue, setSelectedValue] = useState({});
  const [selectedIncluded, setSelectedIncluded] = useState({});

  const getAttributes = (data) => {
    return data.map((item) => {
      return { id: item.id, ...item.attributes };
    });
  };

  const getIncluded = (type, id) => {
    return (
      reportsIncluded.filter(
        (include) =>
          include.type === type && include.id.toString() === id.toString()
      )[0] ?? []
    );
  };

  useEffect(() => {
    const data = {
      q: '',
    };
    listReports(data, callback);
    const viewReportUnlisten = emittery.on('view:report', async (item) => {
      listReports(data, (response) => {
        if (item) {
          const type = response.data.included.find(
            (include) => include.type === 'type' && include.id === item.type_id
          );

          setShowReport(true);
          setSelectedValue(item);
          setSelectedIncluded(type?.attributes?.name ?? '');
        }
      });
    });

    return () => {
      viewReportUnlisten();
    };
  }, []);

  // Handle Response from api
  const callback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200) {
        const items = getAttributes(data.data);
        setReports(items);
        setReportsIncluded(data.included);
      } else {
        toast({
          title: data.errors[0].detail,
          status: `error`,
          position: 'top',
          isClosable: true,
          variant: 'top-accent',
        });
      }
    }
  };

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      <SuccessfulReportModal />
      <ReportsModal
        isModalVisible={showReport}
        handleReports={() => setShowReport(false)}
        data={selectedValue}
        included={selectedIncluded}
      />
      <Box
        width={'100%'}
        display={'flex'}
        justifyContent={'center'}
        flexDirection={'column'}
        backgroundColor={'#0099FF'}
        py={10}
        px={[10, 20, 20, 20, 40]}
      >
        <Text fontSize={25} fontFamily={'Ubuntu'} my={5} color={'white'}>
          Recent Reports
        </Text>
        <Carousel
          responsive={responsive}
          infinite={true}
          customTransition="all .5"
          transitionDuration={500}
          autoPlaySpeed={1000}
          showDots={false}
          swipeable={true}
          removeArrowOnDeviceType={['tablet', 'mobile', 'desktop']}
        >
          {reports.map((item, index) => {
            return (
              <Box
                key={index.display}
                w={['100%', '80%', '80%', '80%', '80%']}
                backgroundColor={'white'}
                borderRadius={10}
              >
                <Image src={item.filename} borderTopRadius={10} w={'100%'} />
                <Box px={5} py={5}>
                  <Text
                    fontSize={28}
                    fontFamily={'Ubuntu-Medium'}
                    color={'red'}
                  >
                    {getIncluded('type', item.type_id).attributes.name}
                  </Text>
                  <Button
                    mt={5}
                    colorScheme={'red'}
                    onClick={() => {
                      setShowReport(true);
                      setSelectedValue(item);
                      setSelectedIncluded(
                        getIncluded('type', item.type_id).attributes.name
                      );
                    }}
                  >
                    View More
                  </Button>
                </Box>
              </Box>
            );
          })}
        </Carousel>
      </Box>
    </>
  );
};

export default SectionC;
