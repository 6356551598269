import React, { useEffect, useRef, useState, useCallback } from 'react';
import AsyncSelect from 'react-select/async';
import { createIncidentReport } from '../actions/actions';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Button,
  Box,
  Input,
  InputGroup,
  FormControl,
  Text,
  Textarea,
  useToast,
  InputRightElement,
} from '@chakra-ui/react';
import toastr from 'toastr';
import { getLocation } from '../../helpers';
import {
  GoogleMap,
  MarkerF as Marker,
  useJsApiLoader,
  Autocomplete,
} from '@react-google-maps/api';
import { emittery } from '../../constants';
import { SearchIcon } from '@chakra-ui/icons';
import pinIcon from '../assets/pin.svg';
import Geocode from 'react-geocode';

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    padding: 10,
    fontFamily: 'Ubuntu',
  }),
  placeholder: () => ({
    fontFamily: 'Ubuntu',
    color: '#6b6b6b',
    position: 'absolute',
    marginLeft: 5,
  }),
  singleValue: () => ({
    display: 'flex',
    flexDirection: 'row',
    position: 'absolute',
    fontFamily: 'Ubuntu',
    wordBreak: 'break-word',
    textOverflow: 'ellipses',
  }),
  control: () => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    borderWidth: 1,
    borderColor: 'black',
    borderRadius: 5,
    marginTop: 10,
    paddingTop: 6,
    paddingBottom: 6,
    padding: 10,
    fontSize: 15,
    fontFamily: 'Ubuntu',
    textOverflow: 'ellipses',
    wordBreak: 'break-word',
  }),
};
const IncidentModal = ({ isModalVisible, handleIncident }) => {
  const toast = useToast();
  const [fileInput, setFileInput] = useState(null);
  const [location, setLocation] = useState('');
  const [locationSearch, setLocationSearch] = useState('');
  const [landmark, setLandmark] = useState('');
  const [category, setCategory] = useState('');
  const [details, setDetails] = useState('');
  const [selectedValue, setSelectedValue] = useState(null);
  const [inputValue, setValue] = useState('');
  const [reporterId, setReporterId] = useState(2);
  const [longitude, setLongitude] = useState(120.87874908843453);
  const [latitude, setLatitude] = useState(15.59223434723856);
  const [zoom, setZoom] = useState(15);
  const [map, setMap] = useState(null);
  const libraries = ['places'];
  const [center, setCenter] = useState({
    lat: latitude,
    lng: longitude,
  });

  Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_TOKEN);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_TOKEN,
    libraries,
  });

  const loadOptions = async (inputValue) => {
    const data = fetch(
      `${process.env.REACT_APP_BASE_URL}/api/guest/type?q=${inputValue}&order_by=created_at&sort_by=asc&per_page=25&page=1&`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        return data.data;
      });

    return data;
  };

  const handleInputChange = (value) => {
    setValue(value);
  };

  const handleChange = (value) => {
    setCategory(value.id);
    setSelectedValue(value);
  };

  const callbackForm = async (res) => {
    const { errors, status, data } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        handleIncident();
        setLocation('');
        setLandmark('');
        setDetails('');
        setCategory('');
        setFileInput();
        emittery.emit('new:report', {
          id: data.data.id,
          ...data.data.attributes,
        });
      } else {
        toast({
          title: errors[0].detail,
          status: `error`,
          position: 'top',
          isClosable: true,
          variant: 'top-accent',
        });
      }
    }
  };

  const handleSubmit = () => {
    const data = {
      geo_lat: map.getCenter().lat(),
      geo_long: map.getCenter().lng(),
      reporter_id: reporterId,
      location: location,
      landmark: landmark,
      file: fileInput,
      type_id: category,
      content: details,
    };
    createIncidentReport(data, callbackForm);
  };

  const onLoad = function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(center);

    map.fitBounds(bounds);

    setTimeout(() => {
      setMap(map);
      setZoom(18);
    }, 1000);
  };

  const onUnmount = function callback(map) {
    setMap(null);
  };

  const hiddenFileInput = useRef(null);

  const handleLoad = (ref) => {
    setLocationSearch(ref);
  };
  const handlePlacesChange = (e) => {
    const geoLoc = locationSearch.getPlace()?.geometry.location.toJSON();

    map.setCenter({
      lat: geoLoc.lat,
      lng: geoLoc.lng,
    });

    setLocation(locationSearch.getPlace().formatted_address, 'js');
  };

  const handleUpload = () => {
    hiddenFileInput.current.click();
  };

  const handleFileSelect = (event) => {
    setFileInput(event.target.files[0]);
  };

  function debounce(func, timeout = 300) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }

  const deboucedSetCenter = useCallback(debounce(setCenter, 500), [setCenter]);

  const onCenterChanged = useCallback(() => {
    if (map !== null) {
      deboucedSetCenter({
        lat: map.getCenter().lat(),
        lng: map.getCenter().lng(),
      });
      setZoom(16);
    }
  }, [map, deboucedSetCenter]);

  const initLocation = async () => {
    try {
      const { latitude, longitude } = await getLocation();
      setLatitude(latitude);
      setLongitude(longitude);
    } catch (error) {
      console.error(error);
      switch (error.code) {
        case error.PERMISSION_DENIED:
          toastr.error('You denied the permissions to get your location.');
          break;
        case error.POSITION_UNAVAILABLE:
          toastr.error('Location information is unavailable.');
          break;
        case error.TIMEOUT:
          toastr.error('The request to get user location timed out.');
          break;
        case error.UNKNOWN_ERROR:
          toastr.error('An unknown error occurred.');
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    initLocation();
  }, []);

  useEffect(() => {
    if (map) {
      Geocode.fromLatLng(map.getCenter().lat(), map.getCenter().lng()).then(
        (response) => {
          const address = response.results[0].formatted_address;
          setLocation(address);
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }, [map?.getCenter().lat()]);
  return (
    <Modal
      motionPreset="slideInBottom"
      isCentered
      isOpen={isModalVisible}
      onClose={handleIncident}
      size={'5xl'}
    >
      <ModalOverlay />
      <ModalContent ml={10}>
        <ModalBody padding={0}>
          <Box
            display={'flex'}
            flexDirection={['column', 'column', 'column', 'row', 'row', 'row']}
          >
            <Box>
              {isLoaded ? (
                <GoogleMap
                  mapContainerStyle={{
                    width: '680px',
                    height: '600px',
                  }}
                  zoom={zoom}
                  center={center}
                  onLoad={onLoad}
                  onUnmount={onUnmount}
                  onCenterChanged={onCenterChanged}
                >
                  <Marker
                    title="Current Location"
                    position={center}
                    icon={pinIcon}
                  />
                </GoogleMap>
              ) : null}
            </Box>
            <Box
              display={'flex'}
              flexDir={'column'}
              px={5}
              py={5}
              width={'100%'}
              maxW={'100%'}
            >
              <Text fontSize={20} paddingTop={4} fontFamily={'Ubuntu-Medium'}>
                Report an Incident
              </Text>
              <FormControl>
                <Autocomplete
                  onLoad={handleLoad}
                  onPlaceChanged={(e) => handlePlacesChange(e)}
                  options={{
                    componentRestrictions: { country: 'ph' },
                    strictBounds: false,
                  }}
                >
                  <InputGroup>
                    <InputRightElement
                      pointerEvents={'none'}
                      children={<SearchIcon marginTop={8} display={'flex'} />}
                    />
                    <Input
                      type={'text'}
                      placeholder={'Location'}
                      my={3}
                      size="lg"
                      value={location}
                      onChange={(e) => setLocation(e.target.value)}
                      borderWidth={1}
                      borderColor={'black'}
                      fontSize={12}
                      _placeholder={{ color: 'black' }}
                      fontFamily={'Ubuntu'}
                    />
                  </InputGroup>
                </Autocomplete>
                <Input
                  type={'text'}
                  placeholder={'Landmark'}
                  my={3}
                  value={landmark}
                  onChange={(e) => setLandmark(e.target.value)}
                  size="lg"
                  borderWidth={1}
                  borderColor={'black'}
                  fontSize={18}
                  _placeholder={{ color: 'blackAlpha.900' }}
                  fontFamily={'Ubuntu'}
                />
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  value={selectedValue}
                  getOptionLabel={(e) => e.attributes.name}
                  getOptionValue={(e) => e.id}
                  loadOptions={loadOptions}
                  onInputChange={handleInputChange}
                  onChange={handleChange}
                  placeholder="Report Type"
                  styles={customStyles}
                />
                <InputGroup my={5}>
                  <Input
                    ref={hiddenFileInput}
                    onChange={handleFileSelect}
                    type={'file'}
                    placeholder={'Enter ID'}
                    style={{ display: 'none' }}
                    fontFamily={'Ubuntu'}
                  />

                  <Input
                    placeholder={'Upload Photo'}
                    borderEndRadius={0}
                    value={fileInput?.name}
                    isDisabled
                    size="lg"
                    borderWidth={1}
                    borderColor={'black'}
                    color={'black'}
                    fontSize={18}
                    _placeholder={{ color: 'black' }}
                    _disabled={{ color: 'black' }}
                    fontFamily={'Ubuntu'}
                  />
                  <Button
                    onClick={handleUpload}
                    borderStartRadius={0}
                    borderEndRadius={15}
                    px={5}
                    fontSize={12}
                    colorScheme={'blue'}
                    backgroundColor="#005086"
                    size="lg"
                    width={40}
                    fontFamily={'Ubuntu'}
                  >
                    Browse
                  </Button>
                </InputGroup>
                <Textarea
                  placeholder="Report Details"
                  mb={5}
                  value={details}
                  onChange={(e) => setDetails(e.target.value)}
                  size="lg"
                  borderWidth={1}
                  borderColor={'black'}
                  fontSize={18}
                  _placeholder={{ color: 'black' }}
                  fontFamily={'Ubuntu'}
                />
              </FormControl>
              <Box
                position={'relative'}
                display={'flex'}
                justifyContent={'end'}
                width={'100%'}
              >
                <Button
                  mx={2}
                  colorScheme={'blue'}
                  backgroundColor="#005086"
                  onClick={handleSubmit}
                  padding={6}
                  fontFamily={'Ubuntu-Medium'}
                  fontSize={15}
                >
                  Send Report
                </Button>
                <Button
                  onClick={handleIncident}
                  colorScheme={'red'}
                  padding={6}
                  fontFamily={'Ubuntu-Medium'}
                  fontSize={15}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default IncidentModal;
