import React, { useState } from 'react';
import { Box, Button } from '@chakra-ui/react';

import 'react-multi-carousel/lib/styles.css';
import IncidentModal from '../modals/incidentModal';

const SectionE = () => {
  const [showIncident, setShowIncident] = useState(false);

  return (
    <>
      <IncidentModal
        isModalVisible={showIncident}
        handleIncident={() => setShowIncident(false)}
      />
      <Box
        width={'100%'}
        justifyContent={'center'}
        flexDirection={'column'}
        backgroundColor={'#0099FF'}
        py={10}
        px={[10, 40]}
      >
        <Box
          w={'100%'}
          backgroundColor={'white'}
          py={20}
          display={'flex'}
          justifyContent={'center'}
          borderRadius={10}
        >
          <Button
            w={['45%']}
            height={['50px', '123px']}
            color={'#FFFFFF'}
            bg={'#960505'}
            bgGradient="linear(90deg, #FF0000 0%, #3E0000 100%)"
            fontSize={[10, 10, 15, 25, 40]}
            borderRadius={20}
            _hover={{ bg: '#960505' }}
            _focus={{ bg: '#960505' }}
            boxShadow={'5px 3px 10px black'}
            onClick={() => {
              setShowIncident(true);
            }}
            fontFamily={'Ubuntu-Medium'}
          >
            REPORT AN INCIDENT
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default SectionE;
