import React from 'react';
import { Box, Image, Flex, Text } from '@chakra-ui/react';

import Logo from '../assets/logo.png';

export default function Header() {
  return (
    <>
      <Box px={4} py={3} backgroundColor={'#0099FF'}>
        <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
          <Box
            display={'flex'}
            spacing={8}
            alignItems={'center'}
            justifyContent={['center', 'start']}
          >
            <Image src={Logo} w={['20%', '4%']} />
            <Box justifyContent={'start'} color={'white'} mx={3}>
              <Text fontFamily={'Ubuntu-Medium'}>
                Sto. Domingo Disaster Inventory
              </Text>
              <Text fontFamily={'Ubuntu-Medium'}>System Management</Text>
            </Box>
          </Box>
        </Flex>
      </Box>
    </>
  );
}
