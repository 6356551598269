import React from 'react';
import { Box, Image } from '@chakra-ui/react';
import Background from '../assets/headerBanner.jpg';
import Logo from '../assets/logo.png';

const SectionA = () => {
  return (
    <Box
      display={'flex'}
      width={'100%'}
      backgroundImage={Background}
      backgroundSize={'cover'}
      backgroundRepeat={'no-repeat'}
      backgroundPosition={'center'}
      justifyContent={'center'}
    >
      <Box
        backgroundColor={'whiteAlpha.500'}
        display={'flex'}
        justifyContent={'center'}
        width={'100%'}
      >
        <Image
          src={Logo}
          w={['75%', '50%', '25%', '35%', '25%']}
          my={40}
          className={'drop-shadow'}
        />
      </Box>
    </Box>
  );
};

export default SectionA;
