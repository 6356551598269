import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { Text, Box, Center, Heading, Button, useToast } from '@chakra-ui/react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import AnnouncementModal from '../modals/announcementModal';
import { listAnnouncement } from '../../api/request';

const SectionC = () => {
  const toast = useToast();

  const [announcements, setAnnouncements] = useState([]);
  const [showAnnouncement, setShowAnnouncement] = useState(false);
  const [selectedValue, setSelectedValue] = useState({});

  useEffect(() => {
    const data = {
      q: '',
    };
    listAnnouncement(data, callback);
  }, []);

  // Handle Response from api
  const callback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200) {
        const items = data.data.map((item, index) => {
          return { id: item.id, ...item.attributes };
        });
        setAnnouncements(items);
      } else {
        toast({
          title: data.errors[0].detail,
          status: `error`,
          position: 'top',
          isClosable: true,
          variant: 'top-accent',
        });
      }
    }
  };
  return (
    <>
      <AnnouncementModal
        isModalVisible={showAnnouncement}
        handleAnnouncemnt={() => setShowAnnouncement(false)}
        data={selectedValue}
      />
      <Box
        width={'100%'}
        display={'flex'}
        justifyContent={'center'}
        flexDirection={'column'}
        backgroundColor={'#0099FF'}
        py={10}
        px={[5, 20, 20, 40, 40]}
      >
        <Text my={5} fontSize={25} color={'white'} fontFamily={'Ubuntu'}>
          Announcement
        </Text>
        <Carousel
          infiniteLoop
          autoPlay
          showStatus={false}
          swipeable={false}
          centerMode
          centerSlidePercentage={100}
        >
          {announcements.map((item, index) => {
            return (
              <Center key={index}>
                <Box
                  borderRadius={5}
                  width={'full'}
                  height={'500px'}
                  backgroundImage={item.filename}
                  backgroundSize={'cover'}
                  fontFamily={'Ubuntu-Medium'}
                >
                  <Box
                    key={index}
                    display={'flex'}
                    bottom={0}
                    height={'25%'}
                    width={'100%'}
                    backgroundColor={'whiteAlpha.700'}
                    position={'absolute'}
                    flexDir={'column'}
                    textAlign={'start'}
                    borderBottomRadius={5}
                  >
                    <Text mx={10} fontSize={28} color={'blue.700'}>
                      {item.name}
                    </Text>
                    <Button
                      w={['50%', '20%', '20%', '10%', '10%']}
                      my={2}
                      mx={8}
                      py={5}
                      px={3}
                      borderRadius={10}
                      bg={'#FE0000'}
                      color={'white'}
                      _hover={{ backgroundColor: '#FE0000' }}
                      _focus={{ backgroundColor: '#FE0000' }}
                      onClick={() => {
                        setShowAnnouncement(true);
                        setSelectedValue(item);
                      }}
                      fontFamily={'Ubuntu-Medium'}
                    >
                      View More
                    </Button>
                  </Box>
                </Box>
              </Center>
            );
          })}
        </Carousel>
      </Box>
    </>
  );
};

export default SectionC;
