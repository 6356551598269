import React from 'react';
import {
  Box,
  SimpleGrid,
  GridItem,
  Center,
  Heading,
  Text,
} from '@chakra-ui/react';
import { ReactComponent as Bullseye } from '../assets/bullseye.svg';
import { ReactComponent as Binoculars } from '../assets/binoculars.svg';

const SectionB = () => {
  return (
    <Box
      display={'flex'}
      width={'100%'}
      backgroundColor={'#0099FF'}
      backgroundSize={'cover'}
      backgroundRepeat={'no-repeat'}
      backgroundPosition={'center'}
      justifyContent={'center'}
    >
      <SimpleGrid
        columns={[1, 1, 1, 2, 2]}
        spacing={[10, 5, 5, 5, 5]}
        color={'white'}
        py={20}
        px={[10, 20]}
      >
        <GridItem textAlign={'center'} px={[5, 20]}>
          <Box
            display={'flex'}
            justifyContent={'center'}
            flexDirection={'column'}
          >
            <Center>
              <Binoculars style={{ height: '90px', width: '90px' }} />
            </Center>

            <Heading mb={5} fontFamily={'Ubuntu-Bold'}>
              VISION
            </Heading>
          </Box>

          <Text lineHeight={2} fontFamily={'Ubuntu'}>
            A competitive agro-industrial and business friendly municipality
            that is peaceful, disaster resilient, progressive and enironmentally
            sustainable with empowered, healthy, and disciplined citizenry under
            an honest, accountable, socially responsive and God-centered
            governance.
          </Text>
        </GridItem>
        <GridItem textAlign={'center'} px={[5, 20]}>
          <Box
            display={'flex'}
            justifyContent={'center'}
            flexDirection={'column'}
          >
            <Center>
              <Bullseye style={{ height: '90px', width: '90px' }} />
            </Center>

            <Heading mb={5} fontFamily={'Ubuntu-Bold'}>
              MISSION
            </Heading>
          </Box>

          <Text lineHeight={2} fontFamily={'Ubuntu'}>
            To provide a better quality of life for its citizens by implementing
            innovation and best practices in Agriculture Services, Disaster
            Preparedness, Climate Change Responsive Engineering, Health
            Services, Education, Business Friendliness, Social Welfare Services,
            Environmental Conservation, Youth Development, and Enrichment of the
            Culture and Arts for the continuous flourishing of the economy
            through effective, accountable and compassionate governance.
          </Text>
        </GridItem>
      </SimpleGrid>
    </Box>
  );
};

export default SectionB;
